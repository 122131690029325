.o-wrap {
    position: relative;
}

///
// Content wrapper
///
.o-wrap__content {
    // Medium Max Desktops
    @include media-max(md) {
        //transition: transform 0.35s;
    }

    .is-open & {
        // Medium Max Desktops
        @include media-max(md) {
            //transform: translate3d(-40%, 0, 0);
        }
    }
}

///
// Navigation wrapper
.o-wrap__nav {
    // Medium Max Desktops
    @include media-max(md) {
        position: fixed;
        top: 0;
        right: 0;
        z-index: z('mobile');
        width: 100%;
        height: 100%;
        padding: rem(60px) rem(30px) rem(30px);
        background-color: color('purple');
        transform: translate3d(100%, 0, 0);
        opacity: 0;
        transition: opacity 0.35s, transform 0.35s;
    }

    .is-open & {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}
