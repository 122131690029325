.c-social {
    display: inline-block;
    width: 32px;
    height: 32px;
    padding-top: rem(3px);
    text-align: center;
    background-color: black;
    transition: transform 0.35s;

    .fa {
        font-size: rem(18px);
        color: white;
    }

    &:hover {
        transform: translate3d(0, -20%, 0);
    }
}

.c-social + .c-social {
    margin-left: rem(5px);
}

///
// Facebook
///
.c-social--facebook {
    background-color: social('facebook');
}

///
// Twitter
///
.c-social--twitter {
    background-color: social('twitter');
}

///
// LinkedIn
///
.c-social--linkedin {
    background-color: social('linkedin');
}

///
// Google Plus
///
.c-social--googleplus {
    background-color: social('googleplus');
}
