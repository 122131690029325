.c-btn {
    display: inline-block;
    padding: rem(5px) rem(20px);
    font-size: rem(14px);
    font-family: $font-minion;
    color: white;
    text-transform: uppercase;
    transition: background-color 0.35s;
}

.c-btn--purple {
    background-color: color('purple');

    @include link-states() {
        background-color: lighten(color('purple'), 10%);
    }
}
