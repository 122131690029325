.c-footer {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    color: $base-font-color;

    // Medium Desktops
    @include media(md) {
        padding-top: rem(60px);
        padding-bottom: rem(60px);
    }

    a {
        font-size: rem(12px);
        color: inherit;
        text-decoration: none;
        text-transform: uppercase;

        @include link-states() {
            color: color('purple');
        }
    }
}

///
// Bottom
///
.c-footer__bottom {
    ul {
        margin-top: 0;
        margin-bottom: rem(10px);
        padding-left: 0;
        line-height: 1;
        list-style: none;
    }

    li {
        // Medium Desktops
        @include media(md) {
            display: inline-block;
            padding-left: rem(10px);
            padding-right: rem(10px);
            vertical-align: top;
        }
    }

    li + li {
        // Medium Desktops
        @include media(md) {
            border-left: 1px solid color('grey', 'border');
        }
    }
}

///
// Blocks Container
///
.c-footer-blocks {
    @include make-row;
}

///
// Block
///
.c-footer__block {
    padding-left: rem(15px);
    padding-right: rem(15px);

    // Medium Max Desktops
    @include media-max(md) {
        text-align: center;
    }

    // Medium Desktops
    @include media(md) {
        float: left;
        width: 25%;
    }

    ul {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
    }
}

.c-footer__block:nth-child(1),
.c-footer__block:nth-child(2),
.c-footer__block:nth-child(3) {
    // Medium Max Desktops
    @include media-max(md) {
        display: none;
    }
}

.c-footer__block--social {
    a {
        display: inline-block;
        margin-bottom: rem(10px);

        // Medium Desktops
        @include media(md) {
            display: block;
        }
    }

    span {
        // Medium Max Desktops
        @include media-max(md) {
            display: none;
        }
    }

    .fa {
        width: 32px;
        height: 32px;
        margin-right: rem(5px);
        padding-top: rem(5px);
        font-size: rem(21px);
        color: white;
        text-align: center;
        background-color: color('grey', 'border');

        // Medium Desktops
        @include media(md) {
            width: 23px;
            height: 23px;
            font-size: rem(14px);
        }
    }
}


///
// Title
///
.c-footer__block__title {
    margin-bottom: rem(10px);
    font-size: rem(16px);
}

///
// Copyright
///
.c-footer__copyright {
    margin-bottom: 0;
    font-size: rem(11px);
    text-transform: uppercase;

    a {
        font-size: inherit;
    }
}
