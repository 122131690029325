.c-testimonial {
    margin: 0;

    // Medium Max Desktops
    @include media-max(md) {
        margin-top: rem(15px);
    }

    p {
        font-size: rem(28px);
        font-family: $font-minion;
        font-style: italic;
        line-height: 1.3;
    }

    cite {
        font-size: rem(14px);
        font-style: normal;
        text-transform: uppercase;
    }
}
