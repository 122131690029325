.c-aircrafts {
    margin-left: neg(rem(15px));
    margin-right: neg(rem(15px));

    // Medium Desktops
    @include media(md) {
        display: flex;
        flex-wrap: wrap;
    }
}

///
// Item
///
.c-aircraft {
    .c-aircrafts & {
        width: 100%;
        margin-bottom: rem(30px);
        padding-left: rem(15px);
        padding-right: rem(15px);

        // Medium Desktops
        @include media(md) {
            display: flex;
            width: 50%;
        }
    }
}

///
// Sold
///
.c-aircraft--sold {
    .c-aircraft__image a:before {
        content: 'Sold';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 20%;
        font-size: rem(48px);
        font-family: $font-minion;
        color: white;
        text-align: center;
        text-transform: uppercase;
        background-color: rgba(black, 0.80);
    }
}

///
// Image
///
.c-aircraft__image {
    position: relative;

    a {
        display: block;
    }

    img {
        width: 100%;
    }
}

///
// Title
///
.c-aircraft__title {
    margin-top: rem(10px);
    margin-bottom: 0;
}

///
// Highlights
///
.c-aircraft__highlights {
    p {
        margin-bottom: rem(15px);
    }
}

///
// Serial Number
///
.c-aircraft__serial {
    margin-bottom: rem(10px);
}

///
// Slider
///
.c-aircraft__slider {
    margin-bottom: rem(30px);
}

///
// Single
///
.c-aircraft--single {
    h2 {
        margin-bottom: 0;
    }

    .c-share {
        margin-bottom: rem(30px);
        padding-bottom: rem(30px);
        border-bottom: 1px solid color('grey', 'border');

        // Medium Max Desktops
        @include media-max(md) {
            margin-top: rem(30px);
        }
    }
}
