///
// Desktop
///
.c-nav--desktop {
    // Medium Max Desktops
    @include media-max(md) {
        display: none;
    }

    // Medium Desktops
    @include media(md) {
        float: right;
    }

    .menu-home {
        display: none;
    }
}

///
// Mobile
///
.c-nav--mobile {
    // Medium Desktops
    @include media(md) {
        display: none;
    }
}

///
// List
///
.c-nav__list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    li {
        position: relative;

        // Medium Max Desktops
        @include media-max(md) {
            opacity: 0;
            transform: translateX(100px);
            transition: all 0.8s;
        }

        // Medium Desktops
        @include media(md) {
            display: inline-block;
            position: relative;
            padding-top: rem(10px);
            padding-bottom: rem(10px);
            vertical-align: top;
        }

        .is-open & {
            // Medium Max Desktops
            @include media-max(md) {
                opacity: 1;
                transform: translateX(0);
            }
        }

        &:hover {
            .c-nav__dropdown {
                // Medium Desktops
                @include media(md) {
                    display: block;
                }
            }
        }
    }

    li + li {
        // Medium Max Desktops
        @include media-max(md) {
            margin-top: rem(15px);
        }

        // Medium Desktops
        @include media(md) {
            margin-left: rem(30px);
        }
    }

    a {
        font-size: rem(16px);
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        transition: color 0.35s;

        // Medium Desktops
        @include media(md) {
            position: relative;
            font-size: rem(14px);
            color: $base-font-color;
        }

        &:hover {
            color: lighten(color('purple'), 10%);

            // Medium Desktops
            @include media(md) {
                color: color('purple');
            }

            &:after {
                // Medium Desktops
                @include media(md) {
                    opacity: 1;
                }
            }

            &:after {
                // Medium Desktops
                @include media(md) {
                    transform: translateY(5px);
                }
            }
        }

        /*

        .is-scrolling & {
            // Medium Desktops
            @include media(md) {
                color: $base-font-color;
            }
        }

        */

        &:after {
            // Medium Desktops
            @include media(md) {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: color('purple');;
                opacity: 0;
                transition: opacity 0.35s, transform 0.35s;
            }

            .is-scrolling & {
                // Medium Desktops
                @include media(md) {
                    background-color: color('purple');
                }
            }
        }

        &:after {
            // Medium Desktops
            @include media(md) {
                bottom: 0;
                transform: translateY(10px);
            }
        }
    }

    .active {
        > a:after {
            opacity: 1;
            transform: translateY(5px);
        }
    }
}

///
// Mobile
///
.c-nav--mobile {
    // Medium Max Desktops
    @include media-max(md) {
        position: fixed;
        top: 0;
        right: 0;
        z-index: z('mobile');
        width: 100%;
        height: 100%;
        padding: rem(60px) rem(30px) rem(30px);
        background-color: color('purple');
        transform: translate3d(100%, 0, 0);
        opacity: 0;
        transition: opacity 0.35s, transform 0.35s;
        overflow: scroll;
    }

    .is-open & {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

///
// Dropdown
///
.c-nav__dropdown {
    display: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    // Medium Max Desktops
    @include media-max(md) {
        margin-top: rem(10px);
        padding-left: rem(10px);
    }

    // Medium Desktops
    @include media(md) {
        //display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 260px;
        padding: rem(20px);
        background-color: color('grey', 'dropdown');
        border: 1px solid color('grey', 'border');
    }

    .is-expanded & {
        display: block;
    }

    li {
        // Medium Desktops
        @include media(md) {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 1.4;
        }
    }

    li:not(:last-child) {
        // Medium Desktops
        @include media(md) {
            margin-bottom: rem(10px);
        }
    }

    li + li {
        // Medium Desktops
        @include media(md) {
            display: block;
            margin-left: 0;
        }
    }

    a {
        // Medium Desktops
        @include media(md) {
            color: $base-font-color;
        }

        @include link-states() {
            color: inherit;
        }
    }
}
