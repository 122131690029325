///
// Logo
///
.c-logo:hover {
    rect {
        fill: lighten(color('purple'), 10%) !important;
    }
}

///
// Gravity Forms
///
.validation_message,
.gfield_description,
.ginput_counter,
.gform_validation_container,
.gform_hidden {
    display: none !important;
}

///
// Soliloquy
///
.soliloquy-image {
    width: 100% !important;
}

.soliloquy-wrapper,
.soliloquy-thumbnails-container {
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.soliloquy-thumbnails-container {
    margin-top: rem(20px) !important;
}

///
// Aircraft Inquiry
///
/*#gform_wrapper_2 {
    margin-top: rem(80px);

    .gform_footer {
        // Medium Desktops
        @include media(md) {
            text-align: center;
        }
    }

    .gform_fields {
        // Medium Desktops
        @include media(md) {
            @include clearfix;
        }
    }

    .gfield {
        // Medium Desktops
        @include media(md) {
            width: 49%;
        }
    }

    #field_2_1,
    #field_2_2,
    #field_2_3,
    #field_2_4, {
        // Medium Desktops
        @include media(md) {
            float: left;
            clear: left;
        }
    }

    #field_2_5,
    #field_2_6 {
        // Medium Desktops
        @include media(md) {
            //position: relative;
            //top: -138px;
            float: right;
            //margin-bottom: neg(rem(138px));
            clear: right;
        }
    }

    #field_2_5 {
        // Medium Desktops
        @include media(md) {
            // Medium Desktops
            @include media(md) {
                position: relative;
                top: -195px;
            }
        }
    }

    #field_2_6 {
        // Medium Desktops
        @include media(md) {
            // Medium Desktops
            @include media(md) {
                position: relative;
                top: -195px;
                margin-bottom: neg(rem(195px));
            }
        }
    }
}*/
