.c-filter {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    // Medium Max Desktops
    @include media-max(md) {
        display: none;
    }

    // Medium Desktops
    @include media(md) {
        margin-bottom: rem(30px);
        text-align: center;
    }
}

///
// Item
///
.c-filter__item {
    // Medium Desktops
    @include media(md) {
        display: inline-block;
        vertical-align: top;
    }

    a {
        display: block;
        padding-left: rem(15px);
        padding-right: rem(15px);
        color: $base-font-color;
        text-decoration: none;
        text-transform: uppercase;
        transition: color 0.35s, background-color 0.35s;

        &:hover {
            color: white;
            background-color: color('purple');
        }
    }

    &.is-active {
        a {
            color: white;
            background-color: color('purple');
        }
    }
}

.c-filter__item + .c-filter__item {
    // Medium Desktops
    @include media(md) {
        margin-left: rem(10px);
    }
}
