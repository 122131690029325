.c-header {
    position: relative;
    z-index: z('header');

    // Medium Max Desktops
    @include media-max(md) {
        height: 90px;
    }

    // Medium Desktops
    @include media(md) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        //padding-top: rem(10px);
        //padding-bottom: rem(10px);
        background-color: white;
        border-bottom: 1px solid color('grey', 'sticky-border');
        transition: background-color 0.35s;
    }

    .logged-in & {
        // Medium Desktops
        @include media(md) {
            top: 32px;
        }
    }

    /*

    .is-scrolling & {
        // Medium Desktops
        @include media(md) {
            background-color: white;
            border-bottom: 1px solid color('grey', 'sticky-border');
        }
    }

    */

    .o-container {
        position: relative;
    }
}
