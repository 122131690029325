html {
    height: 100%;
}

body {
    min-height: 100%;
    margin: 0;
    font-size: $base-font-size;
    font-family: $font-brandon;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: $base-line-height;
    color: $base-font-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

hr {
    width: 100%;
    height: 1px;
    margin-top: rem(30px);
    margin-bottom: rem(30px);
    background-color: color('grey', 'border');
    border: 0;

    // Medium Desktops
    @include media(md) {
        margin-top: rem(60px);
        margin-bottom: rem(60px);
    }
}
