.c-hero {
    position: relative;
    padding-top: rem(100px);
    padding-bottom: rem(100px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    // Medium Max Desktops
    @include media-max(md) {
        top: neg(90px);
        margin-bottom: neg(rem(90px));
    }

    // Medium Desktops
    @include media(md) {
        padding-top: rem(200px);
        padding-bottom: rem(200px);
    }
}

///
// Home
///
.c-hero--home {
    padding-top: rem(178px);
    padding-bottom: rem(178px);

    // Medium Max Desktops
    @include media-max(md) {
        background-position: 65% center;
    }

    // Medium Desktops
    @include media(md) {
        padding-top: rem(355px);
        padding-bottom: rem(355px);
    }
}
