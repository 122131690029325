.c-associations {
    display: flex;
    align-items: center;
    justify-content: center;

    // Medium Max Desktops
    @include media-max(md) {
        flex-wrap: wrap;
    }

    // Medium Desktops
    @include media(md) {
        flex-wrap: nowrap;
    }
}

///
// Association
///
.c-association {
    padding-left: rem(15px);
    padding-right: rem(15px);

    // Medium Max Desktops
    @include media-max(md) {
        //display: flex;
        //flex-basis: 50%;
        //align-items: center;
        //justify-content: center;
        margin-bottom: rem(20px);
    }
}

.c-association:last-child {
    // Portrait
    @media (orientation: portrait) {
        margin-bottom: 0;
    }
}
