.c-list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: rem(15px);
    list-style: none;

    li {
        position: relative;
        margin-bottom: rem(15px);
        padding-left: rem(10px);
        font-size: rem(18px);

        &:before {
            content: '\2022';
            position: absolute;
            top: neg(1px);
            left: 0;
            color: color('purple');
        }
    }
}
