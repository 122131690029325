.c-post-items {
    margin-left: neg(rem(15px));
    margin-right: neg(rem(15px));

    // Medium Desktops
    @include media(md) {
        display: flex;
        flex-wrap: wrap;
    }
}

///
// Item
///
.c-post {
    .c-post-items & {
        width: 100%;
        padding-left: rem(15px);
        padding-right: rem(15px);

        // Medium Desktops
        @include media(md) {
            display: flex;
            width: 33.3333333333%;
        }
    }

    .page-template-template-press &,
    .page-template-template-press-category & {
        margin-bottom: rem(30px);
    }
}

///
// Image
///
.c-post__image {
    display: block;
    margin-bottom: rem(15px);
    border: 1px solid color('grey', 'border');

    img {
        width: 100%;
    }
}

///
// Date
///
.c-post__date {
    font-family: $font-minion;
    color: color('purple');
    text-transform: uppercase;
}

///
// Title
///
.c-post__title {
    margin-bottom: 0;

    a {
        font-style: normal;
        color: $base-font-color;
        text-decoration: none;
    }
}

///
// Header
///
.c-post__header {
    margin-bottom: rem(10px);
}

///
// Inner
///
.c-post__inner {
    width: 100%;
}
