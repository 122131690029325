.c-logo {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 90px;
    max-height: 90px;

    // Medium Desktops
    @include media(md) {
        top: neg(10px);
        left: 15px;
        max-width: 200px;
        max-height: 200px;
        transition: all 0.35s;
    }

    .is-scrolling & {
        // Medium Desktops
        @include media(md) {
            max-width: 100px;
            max-height: 100px;
        }
    }

    rect {
        transition: fill 0.35s;
    }
}

///
// Small
///
.c-logo-small {
    display: inline-block;
    position: absolute;
    top: neg(30px);
    left: 15px;
    width: 100%;
    height: 100%;
    max-width: 160px;
    max-height: 160px;

    // Medium Desktops
    @include media(md) {
        position: absolute;
        top: neg(60px);
    }

    .single-aircraft & {
        // Medium Max Desktops
        @include media-max(md) {
            display: none;
        }

        // Medium Desktops
        @include media(md) {
            top: neg(145px);
        }
    }
}
