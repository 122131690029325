h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: rem(20px);
    font-weight: 400;
    line-height: 1.5;
}

h1,
h2,
h3,
h4,
h6 {
    font-family: $font-minion;
}

h1,
h3,
h4,
h6 {
    color: color('purple');
}

h1,
h4,
h6 {
    text-transform: uppercase;
}

h1 {
    position: relative;
    margin-bottom: rem(30px);
    padding-bottom: rem(10px);
    letter-spacing: 2px;
    @include font-size('h1');

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        max-width: 339px;
        height: 1px;
        margin-left: auto;
        margin-right: auto;
        background-color: color('grey', 'border');
    }
}

h2 {
    @include font-size('h2');
    font-style: italic;
}

h3 {
    @include font-size('h3');
}

h4 {
    @include font-size('h4');
}

h5 {
    @include font-size('h5');
}

h6 {
    @include font-size('h6');
}

a {
    color: color('purple');
    text-decoration: none;
}

p {
    margin-top: 0;
    margin-bottom: rem(30px);
    font-size: rem(18px);
}
