.c-scroll--down {
    display: block;
    width: 100%;
    max-width: 50px;
    transition: transform 0.5s;

    .c-hero--home & {
        // Medium Max Desktops
        @include media-max(md) {
            display: none;
        }

        // Medium Desktops
        @include media(md) {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &:hover {
        transform: translateY(-30%);
    }
}
