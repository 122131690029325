input,
select,
textarea {
    width: 100%;
    padding: rem(10px);
    font-size: rem(16px);
    color: $base-font-color;
    border: 1px solid color('grey', 'medium');
    border-radius: 0;
}

select {
    line-height: 1;
    text-transform: uppercase;
    background-image: url('../../dist/images/bg-arrow.png');
    background-position: 96% center;
    background-repeat: no-repeat;
    appearance: none;
}

textarea {
    height: 174px;
}

input[type="submit"] {
    width: auto;
    margin-top: rem(15px);
    padding: rem(8px) rem(20px);
    font-size: rem(14px);
    font-family: $font-minion;
    color: white;
    text-transform: uppercase;
    background-color: color('purple');
    border: 0;
    transition: background-color 0.35s;

    @include link-states() {
        background-color: lighten(color('purple'), 10%);
    }
}

::-webkit-input-placeholder {
    font-size: rem(14px);
    color: $base-font-color;
    text-transform: uppercase;
}

::-moz-placeholder {
    font-size: rem(14px);
    color: $base-font-color;
    text-transform: uppercase;
}

:-ms-input-placeholder {
    font-size: rem(14px);
    color: $base-font-color;
    text-transform: uppercase;
}

:-moz-placeholder {
    font-size: rem(14px);
    color: $base-font-color;
    text-transform: uppercase;
}
