.c-pager {
    margin-top: rem(30px);
    margin-bottom: rem(30px);
    padding-left: 0;
    list-style: none;
}

///
// Item
///
.c-pager__item {
    display: inline-block;
    vertical-align: top;

    a {
        padding: rem(5px) rem(10px);
        color: white;
        background-color: color('purple');
        text-decoration: none;
        transition: background-color 0.35s;

        @include link-states() {
            background-color: lighten(color('purple'), 10%);
        }
    }

    .current {
        padding: rem(5px) rem(10px);
        color: white;
        background-color: lighten(color('purple'), 10%);
    }
}

.c-pager__item + .c-pager__item {
    margin-left: rem(15px);
}
