.c-section {
    padding-top: rem(30px);
    padding-bottom: rem(30px);

    // Medium Desktops
    @include media(md) {
        padding-top: rem(60px);
        padding-bottom: rem(60px);
    }

    ul:not([class]) {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: rem(15px);
        list-style: none;
    }

    li:not([class]) {
        position: relative;
        margin-bottom: rem(15px);
        padding-left: rem(10px);
        font-size: rem(18px);

        &:before {
            content: '\2022';
            position: absolute;
            top: neg(1px);
            left: 0;
            color: color('purple');
        }
    }
}

///
// Background
///
.c-section--background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

///
// Associations
///
.c-section--associations {
    padding-top: rem(40px);
    padding-bottom: rem(40px);
    background-color: color('grey');
}

///
// Contact
///
.c-section--contact {
    color: color('grey', 'darkest');
    background-color: color('grey', 'darkest');
    background-image: url('../../dist/images/bg-contact-mobile.jpg');

    // Medium Desktops
    @include media(md) {
        background-image: url('../../dist/images/bg-contact.jpg');
    }

    h1 {
        color: inherit;
        text-align: center;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    .o-container {
        position: relative;
    }

    .c-info {
        &:first-of-type {
            margin-top: rem(160px);

            // Medium Desktops
            @include media(md) {
                margin-top: rem(120px);
            }

            .single-aircraft & {
                margin-top: 0;

                // Medium Desktops
                @include media(md) {
                    margin-top: rem(40px);
                }
            }
        }

        span {
            display: block;
            font-family: $font-minion;
        }
    }
}

///
// Testimonials
///
.c-section--testimonials {
    background-image: url('../../dist/images/bg-testimonials-mobile.jpg');

    // Medium Desktops
    @include media(md) {
        background-image: url('../../dist/images/bg-testimonials.jpg');
    }
}

.c-section--alert-banner{
  padding-top: rem(10px);
  padding-bottom: rem(10px);
}
