///
// Widget
///
.c-widget {
    // Medium Max Desktops
    @include media-max(md) {
        display: none;
    }

    label {
        display: none;
    }

    ul {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
    }

    li {
        margin-bottom: rem(10px);
    }

    a {
        color: $base-font-color;
        text-decoration: none;

        @include link-states() {
            color: color('purple');
        }
    }
}

.c-widget + .c-widget {
    // Medium Desktops
    @include media(md) {
        margin-top: rem(30px);
        padding-top: rem(30px);
        border-top: 1px solid color('grey', 'border');
    }
}
