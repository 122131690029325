///
// Center
///
.u-text-center {
    text-align: center;
}

///
// Upper
///
.u-text-upper {
    text-transform: uppercase;
}
